<template>
    <section class="content">
        <table class="table table-striped" ref="tblkelas">
        <thead>
            <tr>
				<th>NAMA UJIAN</th>
				<th>TINDAKAN</th>
            </tr>
        </thead>
        <tbody @click="handleClick"></tbody>
        </table>
    </section>
    <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
    name: "",
    data() {
        return {
        errors: [],
        method: "",
        roles: "",
        formTitle: "Tambah",
        mapel_id: '',
        kelas_id: '',
        program_id: '',
        form: {
            kelas: "",
        },
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
        this.kelas_id = this.$route.params.kelas_id;
        this.mapel_id = this.$route.params.mapel_id;
        this.program_id = this.$route.params.program_id;
    },
    methods: {
        handleClick(e) {
            let self = this;
            if (e.target.matches(".link-role")) {
                this.$router.push({ path: "/permit/" + e.target.dataset.id });
                return false;
            }
            if (e.target.matches(".btn-outline-info")) {
                var btnEl = $(e.target).closest("button").get(0);
                if (btnEl.dataset.action == "detail") {
                    var code = btnEl.dataset.id;
                    var type = btnEl.dataset.type;
                    var paket = btnEl.dataset.paket;
                    var paketItem = btnEl.dataset.paketitem;
                    if (type == 1) {
                        this.$router.push('/rekap-ujian/recapt/skd/'+self.mapel_id+'/'+code+'/'+self.program_id+'/'+self.kelas_id+'/'+paketItem);
                    }
                    if (type == 2) {
                        this.$router.push('/rekap-ujian/recapt/polri/'+self.mapel_id+'/'+paket+'/'+self.program_id+'/'+self.kelas_id+'/'+paketItem);
                    }
                    if (type == 3) {
                        this.$router.push('/rekap-ujian/recapt/tpa/'+self.mapel_id+'/'+code+'/'+self.program_id+'/'+self.kelas_id+'/'+paketItem);
                    }
                    if (type == 4) {
                        this.$router.push('/rekap-ujian-kecermatan/siswa/'+self.kelas_id+'/'+self.program_id+'/'+paket);
                        // console.log(paket)
                    }
                }
                return false;
            }
        },
    },
    mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblkelas, {
        title: "",
        roles: ["read"],
        ajax: "/akademik/ujian/mapel_ujian_hasil",
        columns: [
            { data: "nama_paket" },
            { data: "action",
            render: function (data, type, row, meta) {
                return `<div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-info" data-action="detail" data-id="` + row.ujian_code +`" data-type="` + row.type_id +`" data-paket="` + row.id +`" data-paketitem="` + row.paket_item +`"><i class="fas fa-eye"></i> Rekap</button>`
            } },
        ],
        paramData: function ( d ) {
            d.kelas_id = self.kelas_id;
            d.program_id = self.program_id;
            d.mapel_id = self.mapel_id;
        },
        // filterBy: [0],
        filter: false,
        rowCallback: function (row, data) {
        // $('td:eq(2)', row).html('<a type="button" class="btn btn-primary" data-id="'+data.id+'" data-branch="'+data.branch_id+'">Data Siswa</a>');
        },
    });
},
};
</script>
